import React from 'react';
import moment from 'moment'

import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';

import {
  getId
} from '../../helpers'


const columns = (callBack) => [

  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    align: 'left',
    formatter: (cell, row) => {
      return (
        <div>
          <span className="mb-0 text-sm">
            {cell}
          </span>
        </div>
      )
    }
  },
  {
    dataField: 'role',
    text: 'Role',
    formatter: (cell, row) => {
      return (
        <>
          <span style={{ color: cell === 'admin' ? 'blue' : 'red' }}>
            {cell === 'superadmin' ? 'Super Admin' : 'Admin'}
          </span>
        </>
      )
    }
  },
  {
    dataField: 'mobileNumber',
    text: 'Mobile Number',
    sort: false,
    formatter: (cell, row) => {
      return (
        <>
          <span>
            + {cell}
          </span>
        </>
      )
    }
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: false,
    formatter: (cell, row) => {
      return (
      <div>{cell}</div>
      )
    }
  },
  {
    dataField: 'active',
    text: 'Status',
    formatter: (cell, row) => {
      return (
        <>
          <span>
            {cell ? <p style={{ color: 'green' }}>Active</p> : <p style={{ color: 'red' }}>In-active</p>}
          </span>
        </>
      )
    }
  },
  {
    dataField: 'locked',
    text: 'Locked',
    formatter: (cell, row) => {
      return (
        <>
          {cell ? <p style={{ color: 'red' }}>Locked</p> : <p style={{ color: 'green' }}>Unlocked</p>}
        </>
      )
    }
  },
  {
    dataField: 'createdAt',
    sort: false,
    text: 'Added on',
    formatter: (cell, row) => {
      return (
        <>
          <span>
            {moment(cell).format('ddd, MMMM Do YYYY')}
          </span>
        </>
      )
    }
  },
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            {
              getId() !== row._id && !row.active && (
                <DropdownItem
                  onClick={e => callBack('ENABLE_MODAL_SHOW', 'enableModal', row)}>
                  Activate
                </DropdownItem>
              )
            }
            {
              getId() !== row._id && row.active && (
                <DropdownItem
                  onClick={e => callBack('DISABLE_MODAL_SHOW', 'disableModal', row)}>
                  Deactivate
                </DropdownItem>
              )
            }
            {
              getId() !== row._id && !row.locked && (
                <DropdownItem
                  onClick={e => callBack('LOCK_MODAL_SHOW', 'lockModal', row)}>
                  Lock admin
                </DropdownItem>
              )
            }
            {
              getId() !== row._id && row.locked && (
                <DropdownItem
                  onClick={e => callBack('UNLOCK_MODAL_SHOW', 'unlockModal', row)}>
                  Unlock admin
                </DropdownItem>
              )
            }
            <DropdownItem
              onClick={e => callBack('EDIT_MODAL_SHOW', 'editModal', row)}
            >
              Edit Admin
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
];

export default columns