import React from 'react'

// reactstrap components
import {
  Container, Row,
  Card, CardFooter, Button, FormGroup, InputGroup,
  Input
} from 'reactstrap'

import { Scrollbars } from 'react-custom-scrollbars'
import paginationFactory,
{
  PaginationProvider, PaginationListStandalone
} from "react-bootstrap-table2-paginator"

import BootstrapTable from "react-bootstrap-table-next"

// core components
import columns from './column'

import {
  CreateAdmin,
  // DeleteAdmin,
  GetAdmins,
  UpdateAdmin
} from '../../services/admin'

import DisableAdminModal from '../../components/modals/DisableAdmin'
import EnableAdminModal from '../../components/modals/EnableAdmin'
import LockAdminModal from '../../components/modals/LockAdmin'
import UnlockAdminModal from '../../components/modals/UnlockAdmin'
import CreateAdminModal from '../../components/modals/CreateAdmin'
import EditAdminModal from '../../components/modals/EditAdmin'

import { NotificationContainer } from 'react-notifications'
import createNotification from '../../components/common/Notification'

import {
  EmailValidater,
  difference,
  MobileNoValidator
} from '../../helpers'

import './user.css'

class Users extends React.Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      modal: {},
      page: 1,
      sizePerPage: 50,
      total: 0,
      activeUser: null,
      duplicateUser: null,
      users: [],
      inputValues: {
        active: true,
        locked: false,
        role: 'admin'
      },
      isLoading: false,
      filters: {
        active: "true"
      }
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.inputChangeHandler = this.inputChangeHandler.bind(this)
    this.validator = this.validator.bind(this)
    this.fetchUsers = this.fetchUsers.bind(this)
    this.clearHandler = this.clearHandler.bind(this)
    this.editHandler = this.editHandler.bind(this)
    this.editInputChangeHandler = this.editInputChangeHandler.bind(this)
  }

  componentDidMount() {
    this.fetchUsers()
  }

  inputChangeHandler(name, value) {
    if (name === 'active' || name === 'locked') {
      if (value === "true") {
        value = true
      } else {
        value = false
      }
    }
    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [name]: value
      }
    })
  }

  editInputChangeHandler(name, value) {
    if (name === 'active' || name === 'locked') {
      if (value === "true") {
        value = true
      } else {
        value = false
      }
    }
    if (name === 'mobileNumber') {
      value = value.substring(1)
    }
    this.setState({
      activeUser: {
        ...this.state.activeUser,
        [name]: value
      }
    })
  }

  clearHandler(type) {
    this.toggleModal(type)
    this.setState({
      inputValues: {
        active: true,
        locked: false,
        role: 'admin'
      }
    })
  }

  validator() {
    const { inputValues } = this.state
    if (
      inputValues.email
      && EmailValidater(inputValues.email)
      && inputValues.name
      && inputValues.mobileNumber
      && MobileNoValidator(inputValues.mobileNumber)
      && inputValues.password
      && inputValues.active !== undefined
      && inputValues.locked !== undefined
    ) {
      createNotification('Wrong values entered!')
      return true
    }
    return false
  }

  async submitHandler(e) {
    try {
      e.preventDefault()
      const { inputValues } = JSON.parse(JSON.stringify(this.state))
      console.log('inputValues', inputValues, this.state)
      if (this.validator()) {
        inputValues.isSuperAdmin = inputValues.role === 'admin' ? false : true
        inputValues.mobileNumber = inputValues.mobileNumber.substring(1)
        this.setState({
          isLoading: true
        })
        const { data } = await CreateAdmin(inputValues)
        this.setState({
          isLoading: false
        })
        if (data.status === 201) {
          createNotification('success', `New Admin ${this.state.inputValues.name} added successfully.`)
          this.setState({
            inputValues: {}
          })
          this.toggleModal('createModal')
          this.fetchUsers()
        } else {
          createNotification('error', 'Could not create admin, please try again later.')
        }
      } else {
        createNotification('error', 'Invalid values entered!')
      }

    } catch (error) {
      this.setState({
        isLoading: false
      })
      createNotification('error', 'Something went wrong!')
    }
  }

  async editHandler(e) {
    try {
      e.preventDefault()
      const { activeUser, initActiveUser } = this.state
      const diffObject = difference(initActiveUser, activeUser)
      if (Object.keys(activeUser)) {
        if (diffObject.newNotes) {
          diffObject.notes = diffObject.newNotes
        }
        delete diffObject.newNotes
        if (diffObject.email && diffObject.mobileNumber) {
          if (EmailValidater(diffObject.email) && MobileNoValidator(diffObject.mobileNumber)) {
            if (diffObject.role) {
              diffObject.isSuperAdmin = diffObject.role === 'admin' ? false : true
            }
            // diffObject.mobileNumber = diffObject.mobileNumber.substring(1)
            this.setState({
              isLoading: true
            })
            const { data } = await UpdateAdmin(this.state.activeUser._id, diffObject)
            this.setState({
              isLoading: false
            })
            if (data.status === 200) {
              createNotification('success', `Admin ${this.state.activeUser.name} updated successfully.`)
              this.setState({
                inputValues: {},
                activeUser: null,
                duplicateUser: null
              })
              this.toggleModal('editModal')
              this.fetchUsers()
            } else {
              createNotification('error', 'Could not update the admin!')
            }
          } else {
            createNotification('error', 'Mobile no. or email is invalid.')
          }
        } else if (diffObject.email && !diffObject.mobileNumber) {
          if (EmailValidater(diffObject.email)) {
            if (diffObject.role) {
              diffObject.isSuperAdmin = diffObject.role === 'admin' ? false : true
            }
            this.setState({
              isLoading: true
            })
            const { data } = await UpdateAdmin(this.state.activeUser._id, diffObject)
            this.setState({
              isLoading: false
            })
            if (data.status === 200) {
              createNotification('success', `Admin ${this.state.activeUser.name} updated successfully.`)
              this.setState({
                inputValues: {},
                activeUser: null,
                duplicateUser: null
              })
              this.toggleModal('editModal')
              this.fetchUsers()
            } else {
              createNotification('error', 'Could not update the admin!')
            }
          } else {
            createNotification('error', 'Email is Invalid!')
          }
        } else if (diffObject.mobileNumber && !diffObject.email) {
          if (MobileNoValidator(diffObject.mobileNumber)) {
            if (diffObject.role) {
              diffObject.isSuperAdmin = diffObject.role === 'admin' ? false : true
            }
            // diffObject.mobileNumber = diffObject.mobileNumber.substring(1)
            if (diffObject.newNotes) {
              diffObject.notes = diffObject.newNotes
            }
            this.setState({
              isLoading: true
            })
            const { data } = await UpdateAdmin(this.state.activeUser._id, diffObject)
            this.setState({
              isLoading: false
            })
            if (data.status === 200) {
              createNotification('success', `Admin ${this.state.activeUser.name} updated successfully.`)
              this.setState({
                inputValues: {},
                activeUser: null,
                duplicateUser: null
              })
              this.toggleModal('editModal')
              this.fetchUsers()
            } else {
              createNotification('error', 'Could not update the admin!')
            }
          } else {
            createNotification('error', 'Mobile no. is Invalid!')
          }
        }
        else {
          this.setState({
            isLoading: true
          })
          if (diffObject.role) {
            diffObject.isSuperAdmin = diffObject.role === 'admin' ? false : true
          }
          const { data } = await UpdateAdmin(this.state.activeUser._id, diffObject)
          this.setState({
            isLoading: false
          })
          if (data.status === 200) {
            createNotification('success', `Admin ${this.state.activeUser.name} updated successfully.`)
            this.setState({
              activeUser: null,
              duplicateUser: null
            })
            this.toggleModal('editModal')
            this.fetchUsers()
          } else {
            createNotification('error', 'Could not update the admin!')
          }
        }
      } else {
        createNotification('error', 'Please edit something to update!')
      }
    } catch (error) {
      this.setState({
        isLoading: false
      })
      createNotification('error', 'Something went wrong!')
    }
  }

  async fetchUsers() {
    try {
      const { page, sizePerPage, sortField, sortOrder, filters } = this.state
      const parsedFilters = JSON.parse(JSON.stringify(filters))
      if (parsedFilters.active) {
        if (parsedFilters.active === "true") {
          parsedFilters.active = true
        } else {
          parsedFilters.active = false
        }
      }
      const { data } = await GetAdmins(null, page, sizePerPage, sortField, sortOrder, parsedFilters)
      console.log(data);
      if (data.status === 200 && data.message.users && data.message.users.length) {
        this.setState({
          users: data.message.users,
          total: data.message.pagination.total,
        })
      } else {
        this.setState({ users: [], total: 0, })
        createNotification('info', 'No admins exist.')
      }
    } catch (error) {
      console.log('e', error)
      createNotification('error', 'Admins could not be fetched!')
      this.setState({ error, isLoading: false })
    }
  }


  disableUser = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return
      const { data } = await UpdateAdmin(this.state.activeUser._id, {
        active: false
      })
      if (data.status === 200) {
        this.setState({ modal: false, activeUser: null })
        createNotification('success', 'Admin disabled successfully!')
        this.fetchUsers()
      } else {
        createNotification('error', 'Admin could not be disabled!')
      }
    } catch (error) {
      createNotification('error', 'Something went wrong!')
    }

  }

  enableUser = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return
      const { data } = await UpdateAdmin(this.state.activeUser._id, {
        active: true
      })
      if (data.status === 200) {
        this.setState({ modal: false, activeUser: null })
        createNotification('success', 'Admin enabled successfully!')
        this.fetchUsers()
      } else {
        createNotification('error', 'Admin could not be enabled!')
      }
    } catch (error) {
      createNotification('error', 'Something went wrong!')
    }
  }

  lockAdmin = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return
      const { data } = await UpdateAdmin(this.state.activeUser._id, {
        locked: true
      })
      if (data.status === 200) {
        this.setState({ modal: false, activeUser: null })
        createNotification('success', 'Admin locked successfully!')
        this.fetchUsers()
      } else {
        createNotification('error', 'Admin could not be locked!')
      }
    } catch (error) {
      createNotification('error', 'Something went wrong!')
    }
  }

  unlockAdmin = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return
      const { data } = await UpdateAdmin(this.state.activeUser._id, {
        locked: false
      })
      if (data.status === 200) {
        this.setState({ modal: false, activeUser: null })
        createNotification('success', 'Admin unlocked successfully!')
        this.fetchUsers()
      } else {
        createNotification('error', 'Admin could not be unlocked!')
      }
    } catch (error) {
      createNotification('error', 'Something went wrong!')
    }
  }

  handleTableChange = (type, { page, sizePerPage, ...rest }) => {

    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchUsers)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchUsers)
    }
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
      initActiveUser: user,
      inputValues: {
        active: true,
        locked: false,
        role: 'admin'
      }
    }))
  }

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'ENABLE_MODAL_SHOW':
        this.toggleModal(...rest)
        break
      case 'DISABLE_MODAL_SHOW':
        this.toggleModal(...rest)
        break
      case 'LOCK_MODAL_SHOW':
        this.toggleModal(...rest)
        break
      case 'UNLOCK_MODAL_SHOW':
        this.toggleModal(...rest)
        break
      case 'EDIT_MODAL_SHOW':
        this.toggleModal(...rest)
        break
      default:
        break
    }
  }


  render() {
    const { users } = this.state
    const paginationOption = {
      custom: true,
      totalSize: this.state.total,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
    }
    const pagination = paginationFactory(paginationOption)
    return (
      <>
        <NotificationContainer />
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col" style={{ marginBottom: '1%' }}>
              <Button className='float-right' color='primary' onClick={(e) => this.toggleModal('createModal')}>
                <i className='fa fa-plus' />
                {' '}Create Admin
              </Button>
            </div>
          </Row>
          <Row>
            <div className="col">
              <Card>
                <div className="row" style={{ padding: '20px 0px 20px 20px' }}>
                  <div className="col-sm-3"><FormGroup>
                    <InputGroup>
                      <Input
                        type="select"
                        value={this.state.filters.active}
                        onChange={(e) => {
                          const { filters } = this.state
                          this.setState({
                            filters: {
                              ...filters,
                              active: e.target.value
                            }
                          })
                        }}
                      >
                        <option value="true">Active</option>
                        <option value="false">In-active</option>
                      </Input>
                    </InputGroup>

                  </FormGroup></div>
                  <div className="col"><InputGroup>
                    <Button onClick={this.fetchUsers}>Filter</Button>
                  </InputGroup></div>
                </div>
              </Card>
            </div>
          </Row>
          <Row>
            <div className="col">
              <Card >
                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                      <>
                        <div className="table-responsive">
                          <Scrollbars style={{ height: '50vh' }}>
                            <BootstrapTable
                              remote
                              keyField='_id'
                              data={users}
                              columns={columns(this.tableCallBack)}
                              bootstrap4={true}
                              bordered={false}
                              onTableChange={this.handleTableChange}
                              noDataIndication="no records"
                              {...paginationTableProps}
                            />
                          </Scrollbars>
                        </div>

                        <CardFooter>
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </CardFooter>
                      </>
                    )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <DisableAdminModal
          isOpen={this.state.modal.disableModal}
          toggleModalHandler={() => this.toggleModal('disableModal')}
          className={this.props.className}
          disableHandler={this.disableUser}
        />
        <EnableAdminModal
          isOpen={this.state.modal.enableModal}
          toggleModalHandler={() => this.toggleModal('enableModal')}
          className={this.props.className}
          enableHandler={this.enableUser}
        />
        <LockAdminModal
          isOpen={this.state.modal.lockModal}
          toggleModalHandler={() => this.toggleModal('lockModal')}
          className={this.props.className}
          lockHandler={this.lockAdmin}
        />
        <UnlockAdminModal
          isOpen={this.state.modal.unlockModal}
          toggleModalHandler={() => this.toggleModal('unlockModal')}
          className={this.props.className}
          unlockHandler={this.unlockAdmin}
        />
        <CreateAdminModal
          isOpen={this.state.modal.createModal}
          toggleModalHandler={() => this.toggleModal('createModal')}
          className={this.props.className}
          inputChangeHandler={(name, value) => this.inputChangeHandler(name, value)}
          submitHandler={this.submitHandler}
          inputValues={this.state.inputValues}
          isLoading={this.state.isLoading}
          clearHandler={() => this.clearHandler('createModal')}
        />
        <EditAdminModal
          isOpen={this.state.modal.editModal}
          toggleModalHandler={() => this.toggleModal('editModal')}
          className={this.props.className}
          inputChangeHandler={(name, value) => this.editInputChangeHandler(name, value)}
          submitHandler={this.editHandler}
          inputValues={this.state.activeUser || {}}
          isLoading={this.state.isLoading}
          clearHandler={() => this.clearHandler('editModal')}
        />
      </>
    )
  }
}

export default Users
