import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthFooter from "../components/Footers/AuthFooter.jsx";

import routes from "../routes";

import './custom.css'

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    if (sessionStorage.getItem('token')) {
      return <Redirect from={this.props.location.pathname} to='/admin/index' />
    } else {
      return routes.map((prop, key) => {
        if (prop.layout === "/auth") {
           return (
             <Route
               path={prop.layout + prop.path}
               component={prop.component}
               key={key}
             />
           );
         } else {
           return null
         }
     });
    }
  };
  getDefaultRoutes = routes => {
    const route = routes.filter((r) => this.props.location.pathname === r.layout + r.path)
    if (!route.length) {
      return <Redirect from={this.props.location.pathname} to='/auth/login' />
    }
    return null
  }
  render() {
    return (
      <>
        <div className="main-content">
          <div className="header bg-gradient-info py-7 py-lg-8 custom-header">
            <Container>
              <div className="header-body text-center mb-4">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                  <img
                  alt="..."
                  src={require('../assets/img/brand/LOGO_IMG_O_Web_d200.png')}
                  width="30%"
                />
                    <h1 className="text-white">Super Admin</h1>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                {this.getDefaultRoutes(routes)}
              </Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
