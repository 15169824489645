import {
  API_ENDPOINT
} from './config'

export default {
  routes: {
    auth: {
      login: `${API_ENDPOINT}login`,
      verifyOtp: `${API_ENDPOINT}verify-otp`,
      forgotPassword: `${API_ENDPOINT}forgot-password`,
      verifyResetAttempt: `${API_ENDPOINT}verify-reset-attempt`,
      resetPassword: `${API_ENDPOINT}reset-password`
    },
    admin: {
      getAdmins: `${API_ENDPOINT}`,
      createAdmin: `${API_ENDPOINT}`,
      updateAdmin: `${API_ENDPOINT}`,
      deleteAdmin: `${API_ENDPOINT}`
    }
  }  
}
