import { NotificationManager } from 'react-notifications'

export default (
    type='info', 
    message='', 
    title='', 
    timeOut=3000, 
    callback=() => {}, 
    priority=false
  ) => {
  switch (type) {
    case 'info':
      return NotificationManager.info(message, title, timeOut, callback, priority)
    case 'success':
      return NotificationManager.success(message, title, timeOut, callback, priority)
    case 'warning':
      return NotificationManager.warning(message, title, timeOut, callback, priority)
    case 'error':
      return NotificationManager.error(message, title, timeOut, callback, priority)
    default:
      return NotificationManager.info(message, title, timeOut, callback, priority)
  }
}