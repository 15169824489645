import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
// reactstrap components
import { Container } from "reactstrap"
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx"
import AdminFooter from "../components/Footers/AdminFooter.jsx"
import Sidebar from "../components/Sidebar/Sidebar.jsx"

import routes from "../routes"

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.refs.mainContent.scrollTop = 0
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  getDefaultRoutes = routes => {
    const route = routes.filter((r) => this.props.location.pathname === r.layout + r.path)
    if (!route.length) {
      return <Redirect from={this.props.location.pathname} to='/admin/users' />
    }
    return null
  }
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name
      }
    }
    return "Devotted"
  }
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "#",
            imgSrc: require("../assets/img/brand/LOGO_IMG_O_Web_d200.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            {this.getDefaultRoutes(routes)}
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    )
  }
}

export default Admin
