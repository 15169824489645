import React from "react"
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner
} from "reactstrap"
import { NotificationContainer } from 'react-notifications'
import createNotification from '../components/common/Notification'

import {
  Login as LoginService,
  VerifyOtp as VerifyOtpService
} from '../services/auth'

import { EmailValidater, setItemsInSessionStorage } from '../helpers'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      otp: "",
      isLoading: false,
      isOtpSaving: false,
      otpSent: false,
      disableEmail: false,
      disablePassword: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleForgotPassword = this.handleForgotPassword.bind(this)
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this)
    this.validate = this.validate.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleForgotPassword(e) {
    e.preventDefault()
    this.props.history.push("/auth/forgot-password")
  }

  validate() {
    const { email, password } = this.state
    if (email !== '' && EmailValidater(email) && password !== '') {
      return true
    }
    createNotification('error', 'Invalid email or password!')
  }

  async handleSubmit() {
    try {
      if (this.validate()) {
        const { email, password } = this.state
        this.setState({ isLoading: true })
        let { data } = await LoginService(email, password)

        //  set loading to false
        this.setState({ isLoading: false })
        if (data.status === 200) {
          this.setState({
            // email: "",
            password: "",
            disableEmail: true,
            disablePassword: true,
            otpSent: true
          })
          createNotification('success', 'OTP sent successfully.')
        } else {
          createNotification("error", data.message)
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false
      })
      createNotification("error", "Something went wrong")
    }
  }

  async handleOtpSubmit() {
    try {
      const { otp } = this.state
      if (otp) {
        this.setState({
          isOtpSaving: true
        })
        const { data } = await VerifyOtpService(otp, this.state.email)
        console.log(data);
        this.setState({
          isOtpSaving: false
        })
        if (data.status === 200 || data.success == true) {
          setItemsInSessionStorage(data)
          this.props.history.push("/admin/index")
        } else {
          createNotification('error', 'OTP is Invalid!')
        }
      } else {
        createNotification('error', 'Please enter OTP!')
      }

    } catch (e) {
      this.setState({
        isOtpSaving: false
      })
      createNotification("error", "Something went wrong")
    }
  }

  render() {
    const {
      email,
      password,
      otp,
      disableEmail,
      disablePassword,
      isLoading,
      isOtpSaving,
      otpSent
    } = this.state
    return (
      <>
        <NotificationContainer />
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      value={email}
                      placeholder="Email"
                      type="email"
                      name="email"
                      disabled={disableEmail}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      value={password}
                      placeholder="Password"
                      type="password"
                      name="password"
                      disabled={disablePassword}
                    />
                  </InputGroup>
                </FormGroup>
                {disablePassword && disableEmail ? (
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-mobile" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={this.handleChange}
                        value={otp}
                        placeholder="OTP"
                        type="test"
                        name="otp"
                      />
                    </InputGroup>
                  </FormGroup>
                ) : null}
                {
                  !otpSent ? (
                    !isLoading ? (
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          Sign in
                      </Button>
                      </div>
                    ) : (
                        <div className="text-center">
                          <Spinner size='md' color="primary" />
                        </div>
                      )
                  ) : null
                }
                {
                  otpSent ? (
                    !isOtpSaving ? (
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={this.handleOtpSubmit}
                        >
                          Submit OTP
                    </Button>
                      </div>
                    ) : (
                        <div className="text-center">
                          <Spinner size='md' color="primary" />
                        </div>
                      )
                  ) : null
                }
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={this.handleForgotPassword}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    )
  }
}

export default Login
