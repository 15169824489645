import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner
} from 'reactstrap'
import moment from 'moment'

import {
  getId
} from '../../helpers'

export default ({
  isOpen,
  toggleModalHandler,
  className,
  inputChangeHandler,
  submitHandler,
  inputValues,
  isLoading,
  clearHandler
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModalHandler}
      className={className}
    >
      <ModalHeader toggle={toggleModalHandler}>Edit admin</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Name"
                rows="3"
                type="text"
                name='name'
                required
                value={inputValues.name || ''}
                onChange={e => inputChangeHandler(e.target.name, e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          {/* <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-key"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Password"
                rows="3"
                type="password"
                name='password'
                required
                value={inputValues.password || ''}
                onChange={e => inputChangeHandler(e.target.name, e.target.value)}
              />
            </InputGroup>
          </FormGroup> */}
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                rows="3"
                type="email"
                name='email'
                required
                value={inputValues.email || ''}
                onChange={e => inputChangeHandler(e.target.name, e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-mobile"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Mobile no."
                rows="3"
                type="text"
                name='mobileNumber'
                required
                value={'+' + inputValues.mobileNumber || '+91'}
                onChange={e => inputChangeHandler(e.target.name, e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          {
            getId() !== inputValues._id ? (
              <>
                <FormGroup className="mb-3">
                  <InputGroup className="input-gr oup-alternative">
                    <Input
                      rows="3"
                      type="select"
                      name='active'
                      required
                      onChange={e => inputChangeHandler(e.target.name, e.target.value)}
                      value={inputValues.active}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>In-active</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      rows="3"
                      type="select"
                      name='locked'
                      required
                      onChange={e => inputChangeHandler(e.target.name, e.target.value)}
                      value={inputValues.locked}
                    >
                      <option value={true}>Locked</option>
                      <option value={false}>Unlocked</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      rows="3"
                      type="select"
                      name='role'
                      required
                      onChange={e => inputChangeHandler(e.target.name, e.target.value)}
                      value={inputValues.role}
                    >
                      <option value={'admin'}>Admin</option>
                      <option value={'superadmin'}>SuperAdmin</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
              </>
            ) : null
          }
          {
            <>
              {
                inputValues.notes && Array.isArray(inputValues.notes) && inputValues.notes.length ? (
                  <h4>Existing Notes: </h4>
                ) : null
              }
              {
                inputValues.notes && Array.isArray(inputValues.notes) && inputValues.notes.map(i => (
                  <div style={{ paddingBottom: '10px' }}>
                    <p style={{ marginBottom: '0px', fontWeight: 'bolder' }}>{moment(i.date).format('ddd, MMMM Do YYYY')}</p>
                    <p>{i.text}</p>
                  </div>
                ))
              }
            </>
          }
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <Input
                rows="3"
                placeholder="New notes"
                type="textarea"
                name='newNotes'
                onChange={e => inputChangeHandler(e.target.name, e.target.value)}
                value={inputValues.newNotes}
              />
            </InputGroup>
          </FormGroup>
          <div className="modal-footer">
            {
              !isLoading ? (
                <Button color="primary" type="submit">
                  Edit
                </Button>
              ) : (
                  <div className='text-center'>
                    <Spinner color="primary" size="md" />
                  </div>
                )
            }
            <Button color="secondary" onClick={clearHandler} disabled={isLoading}>
              Cancel
            </Button>
          </div>
        </Form>

      </ModalBody>
    </Modal>
  )
}