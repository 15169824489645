import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./assets/vendor/nucleo/css/nucleo.css"
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
import "./assets/css/argon-dashboard-react.css"

import 'react-notifications/lib/notifications.css'

import './assets/css/custom.css'

import AdminLayout from "./layouts/Admin.jsx"
import AuthLayout from "./layouts/Auth.jsx"


function adminRedirect(props) {
  const AuthUser = sessionStorage.getItem('token');
  if (AuthUser) {
    return <AdminLayout {...props} />
  } else {
    return <Redirect from="/" to="/auth/login" />
  }
}

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/admin"
          render={props => adminRedirect(props)}
        />

        <Route
          path="/"
          render={props => <AuthLayout {...props} />}
        />

      </Switch>
    </BrowserRouter>
  )
}


ReactDOM.render(<App />, document.getElementById('root'));
