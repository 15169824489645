import axios from 'axios'
import endpoints from '../config/url'

const {
  routes: {
    auth: {
      forgotPassword,
      login,
      resetPassword,
      verifyOtp,
      verifyResetAttempt
    }
  }
} = endpoints

export const Login = async (email, password) => {
  return await axios({
    method: 'POST',
    url: login,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    data: {
      email,
      password,
      from: true
    }
  })
}

export const ForgotPassword = async (email) => {
  return await axios({
    method: 'POST',
    url: forgotPassword,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    data: {
      email
    }
  })
}

export const ResetPassword = async (email, password, token) => {
  return await axios({
    method: 'POST',
    url: resetPassword,
    data: {
      email,
      password,
      token
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
}

export const VerifyOtp = async (otp, email) => {
  return axios({
    method: 'POST',
    url: verifyOtp,
    data: {
      otp,
      email
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
}

export const VerifyResetAttempt = async (token) => {
  return axios({
    method: 'GET',
    url: verifyResetAttempt,
    params: {
      token
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
}