import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'

export default ({
  isOpen,
  toggleModalHandler,
  className,
  enableHandler
}) => (
    <Modal
      isOpen={isOpen}
      toggle={toggleModalHandler}
      className={className}
    >
      <ModalHeader toggle={toggleModalHandler}>Activate Admin</ModalHeader>
      <ModalBody>Are you sure you want to activate this admin?</ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={enableHandler}>
          Activate Admin
        </Button>
        <Button color="secondary" onClick={toggleModalHandler}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
