import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'

export default ({
  isOpen,
  toggleModalHandler,
  className,
  unlockHandler
}) => (
    <Modal
      isOpen={isOpen}
      toggle={toggleModalHandler}
      className={className}
    >
      <ModalHeader toggle={toggleModalHandler}>Unlock Admin</ModalHeader>
      <ModalBody>Are you sure you want to unlock this admin?</ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={unlockHandler}>
          Unlock Admin
        </Button>
        <Button color="secondary" onClick={toggleModalHandler}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
