import axios from 'axios'
import endpoints from '../config/url'

const {
  routes: {
    admin: {
      createAdmin,
      deleteAdmin,
      getAdmins,
      updateAdmin
    }
  }
} = endpoints

const getToken = () => {
  try {
    return sessionStorage.getItem('token')
  } catch (e) {
    return null
  }
}

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  'Authorization': `Bearer ${getToken()}`
}

export const CreateAdmin = async (body) => {
  return await axios({
    method: 'POST',
    url: createAdmin,
    headers,
    data: body
  })
}

export const GetAdmins = async (id, page, sizePerPage, sortField, sortOrder, filters) => {
  if (id) {
    let params = {
      id,
      page,
      sizePerPage
    }
    if (sortField && sortOrder) {
      params.sortField = sortField
      params.sortOrder = sortOrder
    }
    if (filters) {
      params.filters = filters
    }
    console.log(getAdmins);
    console.log(params);
    return await axios({
      method: 'GET',
      url: getAdmins,
      headers,
      params
    })
  }
  let params = {
    page,
    sizePerPage
  }
  if (sortField && sortOrder) {
    params.sortField = sortField
    params.sortOrder = sortOrder
  }
  if (filters) {
    params.filters = filters
  }
  return await axios({
    method: 'GET',
    url: getAdmins,
    headers,
    params
  })
}

export const DeleteAdmin = async (body) => {
  return await axios({
    method: 'DELETE',
    url: deleteAdmin,
    headers,
    data: body
  })
}

export const UpdateAdmin = async (id, body) => {
  console.log(body);
  console.log(id);
  return await axios({
    method: 'PATCH',
    url: `${updateAdmin}${id}`,
    headers,
    data: body
  })
}