import React from "react"

// reactstrap components
import {
  Card,
  CardBody,
  Col,
  Spinner
} from "reactstrap"

import {
  VerifyResetAttempt
} from '../services/auth'

class Verify extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isVerified: false
    }
  }

  async componentDidMount() {
    try {
      const params = new URLSearchParams(window.location.search)
      if (params.get('token')) {
        const { data } = await VerifyResetAttempt(params.get('token'))
        if (data.status === 200) {
          this.setState({
            isVerified: true,
            isLoading: false
          })
          this.props.history.push(`/auth/reset?email=${data.message.email}&token=${data.message.token}`)
        } else {
          this.setState({
            isLoading: false,
            isVerified: false
          })
        }
      } else {
        this.setState({
          isLoading: false,
          isVerified: false
        })
        this.props.history.push("/auth/login")
      }
    } catch (e) {
      this.setState({
        isLoading: false,
        isVerified: false
      })
    }
  }

  render() {
    const { isLoading, isVerified } = this.state
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              {
                !isLoading ? (
                  isVerified ? (
                    <div className="text-center">
                      <h3>Verified Successfully, now redirecting!</h3>
                    </div>
                  ) : (
                      <div className="text-center">
                        <h3>Could not verify, token invalid!</h3>
                      </div>
                    )
                ) : (
                    <div className="text-center">
                      <Spinner color="primary" size="md" />
                    </div>
                  )
              }
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}

export default Verify
