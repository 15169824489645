// import Index from './views/Index'
import User from './views/Users/User'
import Login from './views/Login'
import Forgot from './views/Forgot'
import Verify from './views/Verify'
import Reset from './views/Reset'

var routes = [
  // {
  //   path: '/index',
  //   name: 'Super Admin Dashboard',
  //   icon: 'ni ni-tv-2 text-primary',
  //   component: Index,
  //   layout: '/admin',
  //   invisible: true
  // },
  {
    path: '/users',
    name: 'Admins',
    icon: 'fa fa-users',
    component: User,
    layout: '/admin',
    invisible: false
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    icon: 'ni ni-key-25 text-info',
    component: Forgot,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/verify-reset-attempt',
    name: 'Verify',
    icon: 'ni ni-key-25 text-info',
    component: Verify,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/reset',
    name: 'Reset',
    icon: 'ni ni-key-25 text-info',
    component: Reset,
    layout: '/auth',
    invisible: true
  }
];
export default routes;
