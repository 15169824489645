import {
  transform,
  isEqual,
  isArray,
  isObject
} from 'lodash'

export const EmailValidater = (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/.test(email)
export const MobileNoValidator = (mobileNo) => /^\+?([0-9]{1,2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(mobileNo)

export const difference = (origObj, newObj) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

export const setItemsInSessionStorage = (message) => {
  console.log(message);
  sessionStorage.setItem('token', message.token)
  sessionStorage.setItem('name', message.adminUser.name)
  sessionStorage.setItem('email', message.adminUser.email)
  sessionStorage.setItem('mobileNumber', message.adminUser.mobileNumber)
  sessionStorage.setItem('_id', message.adminUser._id)
}

export const getUserName = () => {
  return sessionStorage.getItem('name')
}

export const getToken = () => {
  return JSON.parse(sessionStorage.getItem('token'))
}

export const getId = () => {
  return sessionStorage.getItem('_id')
}
